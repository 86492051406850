<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 200px )"
    >
        <y-page-head
            :title="title"
            :desc="subtitle"
            icon="md-file-document-box-outline"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />
            </template>
        </y-page-head>

        <main v-if="brief">
            <div class="row">
                <div class="col-sm-8">
                    <y-panel
                        :title="$t('yasnateam.title.details')"
                        icon="md-file-document-box-outline"
                    >
                        <y-row>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.user_position') }}
                                    </p>
                                    <p class="value">
                                        {{ brief.user_position ? brief.user_position : `-` }}
                                    </p>
                                </div>
                            </y-col>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.status') }}
                                    </p>
                                    <p class="value">
                                        <span class="label">
                                            {{ brief.status_label }}
                                        </span>
                                    </p>
                                </div>
                            </y-col>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.company_size') }}
                                    </p>
                                    <p class="value">
                                        {{ brief.company_size ? digitsHelper(brief.company_size) : `-` }}
                                    </p>
                                </div>
                            </y-col>
                        </y-row>

                        <y-row>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.phone') }}
                                    </p>
                                    <p class="value">
                                        <y-form-phone-label
                                            v-if="brief.phone"
                                            :element="{ value: brief.phone }"
                                            copy
                                        />
                                        <template v-else>
                                            {{ `-` }}
                                        </template>
                                    </p>
                                </div>
                            </y-col>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.website') }}
                                    </p>
                                    <p class="value">
                                        <a
                                            v-if="brief.website"
                                            :href="brief.website"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {{ brief.website }}
                                        </a>
                                        <template v-else>
                                            {{ `-` }}
                                        </template>
                                    </p>
                                </div>
                            </y-col>
                            <y-col>
                                <div class="detail-item">
                                    <p class="title">
                                        {{ $t('yasnateam.details.favorite_site') }}
                                    </p>
                                    <p
                                        v-if="brief.favorite_sites && brief.favorite_sites.length"
                                        class="value"
                                    >
                                        <template
                                            v-for="(website, index) in brief.favorite_sites"
                                        >
                                            <a
                                                v-if="website"
                                                :key="index"
                                                :href="website"
                                                class="label ml5"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {{ website }}
                                            </a>
                                        </template>
                                    </p>
                                    <p
                                        v-else
                                        class="value"
                                    >
                                        {{ `-` }}
                                    </p>
                                </div>
                            </y-col>
                        </y-row>
                    </y-panel>

                    <y-panel
                        v-if="brief.items && brief.items.length"
                        :title="$t('yasnateam.title.items')"
                        icon="md-file-delimited"
                        class="mb0"
                    >
                        <div
                            v-for="item in brief.items"
                            :key="item.key"
                            class="detail-item"
                        >
                            <template v-if="item.posts && item.posts.length">
                                <p class="title">
                                    {{ item.tag_title }}
                                </p>

                                <span
                                    v-for="post in item.posts"
                                    :key="post.id"
                                    class="label blue ml5 mb5"
                                >
                                    {{ post.post_title }}
                                </span>
                            </template>
                        </div>
                    </y-panel>

                    <y-timeline :timeline="brief.timeline" />
                </div>

                <div class="col-sm-4">
                    <y-panel
                        v-if="form"
                        :title="$t('yasnateam.title.change_status')"
                        :class="{disabled: $wait.is('submitting-status')}"
                    >
                        <form
                            id="change-status-form"
                            @submit.prevent="saveChangeStatus"
                        >
                            <y-form
                                v-model="model"
                                :params="form"
                            />

                            <div class="ta-l">
                                <y-button
                                    color="blue"
                                    form="change-status-form"
                                    loading-on="submitting-status"
                                >
                                    {{ $t('button.submit') }}
                                </y-button>
                            </div>
                        </form>
                    </y-panel>
                </div>
            </div>
        </main>
    </y-loading>
</template>

<script>
    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import { digits } from '@nodes/helpers/number';

    import { YForm } from '@deps';
    import YFormPhoneLabel from '@deps/form/elements/PhoneLabel';
    import YTimeline from '@deps/Timeline';

    export default {
        name: 'YasnateamBriefSingle',

        components: {
            YForm,
            YFormPhoneLabel,
            YTimeline,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.yasnateam.single')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('yasnateam.title.single'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                brief: null,

                form : null,
                model: {},

                note: null,
            };
        },

        computed: {
            /**
             * Page title
             */
            title() {
                return this.brief && this.brief.company_name ? this.brief.company_name : '';
            },

            /**
             * Page subtitle
             */
            subtitle() {
                return this.brief && this.brief.user_name ? this.brief.user_name : '';
            },
        },

        methods: {

            /**
             * Fetch currencies
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.$route.params.id };
                return this.$services.Yasnateam.briefFormTimeline(params).then((response) => {
                    this.brief = response.data.results;
                    this.form = response.data.metadata.action_form;
                    this.model = {
                        id    : this.$route.params.id,
                        status: response.data.results.status,
                    };

                    // this.note = this.brief.note;
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

            /**
             * Save change status and note
             */
            saveChangeStatus() {
                this.$wait.start('submitting-status');
                return this.$services.Yasnateam.briefFormChangeStatus(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('yasnateam.change_status.message') }));
                    this.reFetch();
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => {
                    this.$wait.end('submitting-status');
                });
            },

            /**
             * Digit helper for using in inline condition
             * 
             * @param {number} value - Number
             */
            digitsHelper(value) {
                return digits(value, this.$i18n.locale);
            },
        },
    };
</script>
